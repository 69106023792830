import LoginComp from 'components/Login/LoginComp'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useLocation } from 'wouter'

Modal.setAppElement('#root') // Para accesibilidad, establece el elemento principal de la aplicación

export default function Login() {
  // Get user context
  const { setUser } = useContext(UserContext)

  // Router (to do redirects)
  const [, setLocation] = useLocation()

  // Form login data
  const [loginData, loginAction] = useFetch()

  // Form operator data
  const [loginOperatorData, loginOperatorAction] = useFetch()

  // State for modal visibility and input value
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  // Trigger login data (to set userContext and show modal)
  useEffect(() => {
    // If Login OK
    if (loginData.data) {
      // Show modal for verification code
      setModalIsOpen(true)
    }

    // If Login Operator OK
    if (loginOperatorData.data) {
      if (loginOperatorData.data) {
        // Set user info to User context (context + localstorage)
        setUser(loginOperatorData.data)

        // Redirect
        setLocation('/orders/grid')
      }
    }
  }, [loginData.data, loginOperatorData.data])

  const handleSubmitLogin = async (values) => {
    const loginAPIParams = {
      url: 'auth',
      method: 'POST',
      body: values,
      messageKo: 'Usuario y/o contraseña incorrectos'
    }

    // Fetch api Login
    loginAction(loginAPIParams)
  }

  const handleSubmitLoginPin = async (values) => {
    const loginAPIParams2 = {
      url: 'auth/pin',
      method: 'POST',
      body: {
        code: verificationCode,
        email: loginData.data.email,
        password: loginData.data.password
      },
      messageKo: 'PIN incorrecte'
    }

    // Fetch api Login
    loginOperatorAction(loginAPIParams2)
  }

  // const handleVerifyCode = () => {
  //   alert(`Código de verificación ingresado: ${verificationCode}`)
  //   setModalIsOpen(false)
  //   // Aquí puedes manejar la verificación del código y cualquier lógica adicional
  //   // setLocation('/');
  // }

  return (
    <div className="flex flex-col items-center justify-center h-screen p-10">
      <div
        className="relative grid w-full h-full p-12 grid-rows-11 auto-rows-min border-container"
        style={{
          border: '20px solid #00c8bb'
        }}
      >
        <div
          className="absolute px-4 pb-0 pt-0.5 text-4xl bg-white font-notoBold text-turquoise -top-8"
          style={{
            color: '#00c8bb',
            right: '90px'
          }}
        >
          TECHNOFORM
        </div>

        <div className="flex justify-start w-full row-span-5 place-items-end">
          <div>
            <h1 className="m-0 space-x-2 text-4xl leading-none md:text-9xl">
              <span className="font-notoBold" style={{ color: '#0b1f92' }}>Mi</span>
              <span className="uppercase font-notoBold" style={{ color: '#00c8bb' }}>
                Technoform
              </span>
            </h1>

            <h2
              className="m-0 text-2xl leading-none font-notoExtraBold md:text-6xl ps-1.5"
              style={{ color: '#0b1f92' }}
            >
              Extranet service
            </h2>
          </div>
        </div>

        <div className="w-full row-span-5 pl-2 place-content-end">
          <LoginComp
            loading={loginData.loading}
            error={loginData.error}
            handleSubmitLogin={handleSubmitLogin}
          />
        </div>

        <div className="w-full row-span-1 pl-2 place-content-end">
          <p
            className="text-2xl font-notoBold md:text-3xl"
            style={{ color: '#00c8bb' }}
          >
            Soluciones aislantes para ventanas, puertas y fachadas
          </p>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen((prev) => !prev)}
        contentLabel="Código de Verificación"
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            padding: '0px',
            border: '0px',
            width: '30%'
          }
        }}
      >
        <div className="relative w-full">
          <div className="relative bg-white border-4 border-[#00c8bb] rounded-sm">
            <div className="flex items-center justify-between p-4 border-b border-gray-300 rounded-t md:p-5">
              <h3 className="text-lg text-gray-900 font-notoBold">
                Código de verificación
              </h3>

              <button
                onClick={() => setModalIsOpen((prev) => !prev)}
                className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 ms-auto"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>

                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <form className="flex flex-col items-end p-4 md:p-5">
              <div className="grid w-full grid-cols-2 gap-4 mb-4">
                <div className="col-span-2">
                  <input
                    type="text"
                    className="border border-gray-300 text-gray-900 text-sm font-noto rounded-sm outline-none block w-full p-2.5"
                    placeholder="Ingrese el código..."
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, '') // Removes all non-numeric characters
                    }}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    value={verificationCode}
                  />
                </div>
              </div>

              <button
                type="button"
                className="h-10 px-4 font-notoExtraCondensedBlack text-lg text-white hover:text-[#0b1f92] transition-colors duration-150 outline-none bg-[#0b1f92] hover:bg-[#00c8bb]"
                onClick={() => handleSubmitLoginPin()}
                style={{
                  border: '3px solid #00c8bb',
                  borderRadius: '15px'
                }}
              >
                Verificar
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}
