// PropTypes
import React from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import LoginForm from 'forms/login/loginForm'

export default function LoginComp({ loading, error, handleSubmitLogin }) {
  return (
    <div className="w-full max-w-lg">
      <LoginForm loading={loading} handleSubmit={handleSubmitLogin} />

      <div
        className={`relative px-2 py-1 mt-2 text-red-700 bg-red-100 border border-red-400 rounded-sm ${error ? 'visible' : 'invisible'}`}
        role="alert"
      >
        <strong className="font-notoBlack">Error: </strong>
        <span className="block sm:inline font-noto">
          ¡Usuario o contraseña incorrectos!
        </span>
      </div>
    </div>
  )
}

LoginComp.propTypes = {
  handleSubmitLogin: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool
}
