// REACT HOOKS
import { useState } from 'react'

// useLocation()
import { useLocation } from 'wouter'

export default function useFetch() {
  // useLocation():
  const [, setLocation] = useLocation()

  // useState():
  const [loading, setLoading] = useState(false)
  const [isCalled, setIsCalled] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)

  async function fetchAction(params) {
    // Reiniciamos estados:
    setIsCalled(true)
    setData(null)
    setError(null)
    setMessage(null)
    setLoading(true)

    // Crecendiales API:
    const apiCredentials = `Basic ${btoa(
      process.env.REACT_APP_API_USERNAME +
        ':' +
        process.env.REACT_APP_API_PASSWORD
    )}`

    try {
      // Cabeceras:
      const headers = params.formData
        ? { Accept: 'multipart/form-data', Authorization: apiCredentials }
        : { 'Content-Type': 'application/json', Authorization: apiCredentials }

      // Cuerpo:
      const body = params.formData
        ? params.formData
        : JSON.stringify(params.body)

      // Respuesta:
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${params.url}`,
        {
          method: params.method,
          headers,
          body
        }
      )

      // ¿Ha ido bien? Sí:
      if (response.ok) {
        // Guardamos datos de la respuesta:
        const responseData = await response.json()

        setData(responseData)
        setMessage(responseData.message)
      }

      // ¿Ha ido bien? No:
      if (!response.ok) {
        // Verificamos error de autorización:
        if (!params.url.includes('auth') && response.status === 401) {
          // Redirigimos a la pantalla login:
          setLocation('/login')
        } else {
          // Guardamos errores de la respuesta:
          const errorData = await response.json()
          setError(errorData.error)
          console.log(
            'useFetch() detailedError from API call fail: ',
            errorData.detailedError
          )
        }
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return [
    {
      loading,
      isCalled,
      data,
      error,
      message
    },
    fetchAction
  ]
}
