import { UserContext } from 'contexts/UserContext'
import { menuItems } from 'data/menuItems'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Link, useLocation } from 'wouter'
import MenuItems from './MenuItems'

export default function Menu({ device, handleMobileMenu }) {
  // Recogemos el usuario con el que hemos iniciado sesión:
  const { user } = useContext(UserContext)

  const [location] = useLocation()

  if (device === 'mobile') {
    return (
      <>
        {menuItems.map((item) => {
          return (
            <Link
              key={item.slug}
              href={item.slug}
              onClick={() => handleMobileMenu()}
            >
              <a
                className={`${
                  item.slug === location
                    ? 'bg-secondary text-white'
                    : 'text-white hover:bg-secondary hover:text-white'
                } block px-3 py-3 rounded-md text-base font-medium`}
              >
                {item.title}
              </a>
            </Link>
          )
        })}
      </>
    )
  } else {
    return (
      <ul className="space-y-2 font-medium list-none">
        {menuItems &&
          menuItems.map((item, index) => {
            return (
              <MenuItems
                item={item}
                user={user}
                location={location}
                key={index}
              />
            )
          })}
      </ul>
    )
  }
}

Menu.propTypes = {
  device: PropTypes.string,
  handleMobileMenu: PropTypes.func
}
