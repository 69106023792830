// PropTypes
import PropTypes from 'prop-types'

// ICONS
import iconGraph from 'assets/icons/iconSingle.svg'

const DashboardClientes = ({ fieldsDashClientes }) => {
  // * Mostrar información 'fieldsDashClientes':
  // console.log('fieldsDashClientes: ', fieldsDashClientes)

  return (
    <div
      className="flex flex-col col-span-1 relative 
      bg-gray-100 border-2 border-gray-700 rounded
      font-ms-semi 
      sm:w-56 lg:w-90
      sm:h-60 lg:h-60"
    >
      <div className="h-full flex flex-col">
        <div className="flex flex-row justify-start items-center bg-gray-200 border-b-2 border-gray-700 h-auto">
          <div className="px-3">
            <img src={iconGraph} className="w-6" alt="icon" />
          </div>

          <h3 className="text-gray-600 sm:text-sm lg:text-2xl text-base md:text-base pt-2 pb-2 text border-blue-700 font-bold justify-self-center">
            Clientes
          </h3>
        </div>

        <div className="w-full flex flex-grow flex-col px-2 justify-end">
          <hr className="text-gray-700 w-full" />
          <p className="text-base text-gray-600 py-4 font-semibold text-center">
            Total:
            <span className="sm:text-base text border-blue-700">
              {''} &nbsp;&nbsp;
              {(fieldsDashClientes?.TOTAL).toLocaleString() ?? 'n.c.'}
            </span>{' '}
            registros
          </p>
        </div>
      </div>
    </div>
  )
}

export default DashboardClientes

DashboardClientes.propTypes = {
  fieldsDashClientes: PropTypes.object
}
