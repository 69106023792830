// PropTypes & React HOOKS:
import PropTypes from 'prop-types'
import { useEffect, useContext } from 'react'

// COMPONENTS:
import { TitleContext } from 'contexts/TitleContext'

export default function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column
  const previousFilter = localStorage.getItem(column.id)
  const { title } = useContext(TitleContext)

  useEffect(() => {
    localStorage.setItem(column.id, filterValue)
  }, [previousFilter, filterValue])

  useEffect(() => {
    if (previousFilter !== 'undefined') {
      setFilter(previousFilter)
    }
  }, [title])

  return (
    <div className="print:hidden">
      {column.Header === '' ||
      column.Header === 'Enviado' ||
      column.Header === 'Archivo' ||
      column.Header === 'Enviar' ||
      column.Header === 'Eliminar' ? (
        <span></span>
      ) : (
        <input
          className={`w-full py-1 pr-1 border-2 border-gray-200 focus:outline-none hover:border-blue-300 font-noto font-normal rounded-sm px-1 text-gray-400 text-sm`}
          value={filterValue || ''}
          placeholder={`Filtro...`}
          onChange={(e) => setFilter(e.target.value)}
          onClick={(e) => e.stopPropagation()}
        />
      )}
    </div>
  )
}

ColumnFilter.propTypes = {
  column: PropTypes.object.isRequired
}
