// PropTypes & HOOKS
import PropTypes from 'prop-types'
import React, { createContext, useState, useEffect } from 'react'

export const UserContext = createContext(null)

export const UserContextProvider = ({ children }) => {
  const localUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

  const [user, setUser] = useState(localUser)

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    }
  }, [user])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
