// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import AdminTable from 'components/ui/Table/AdminTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function AdminCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  // Columnas:
  const columns = [
    {
      Header: 'Usuario',
      accessor: 'Admin_UserName',
      Filter: ColumnFilter
    },
    {
      Header: 'Nombre',
      accessor: 'Admin_Name',
      Filter: ColumnFilter
    }
  ]

  // Adaptar datos para la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []
    console.log(items[0].Email);
      items.forEach((item) => {
        itemsData.push({
          Admin_Id: item.Id,
          Admin_UserName: item.UserName,
          Admin_Name: item.Name,
          Admin_Password: item.Password,
          Admin_Email: item.Email
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto">
      <div className="align-middle inline-block min-w-full">
        <div className="shadow overflow-hidden border-b border-gray-200">
          {getTableData.length > 0 && (
            <AdminTable
              data={getTableData}
              columns={columns}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}

AdminCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
