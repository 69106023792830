// PropTypes & HOOKS
import PropTypes from 'prop-types'

// FORMIK HOOK
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

// ASSETS
import LockIcon from 'components/icons/LockIcon'
import EmailIcon from 'components/icons/EmailIcon'

export default function LoginForm({ loading, handleSubmit }) {
  // Esquema validación formulario:
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Introduzca un email'),
    password: Yup.string()
      .required('Introduzca una contraseña')
      .min(6, 'La contraseña es demasiado corta. Mínimo 6 caracteres')
  })

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({ errors, touched }) => (
        <Form className="w-full max-w-lg space-y-3">
          <div className="flex flex-col items-start justify-center">
            <div className="flex items-center w-full">
              <label
                htmlFor="email"
                className="block w-1/4 mr-4 text-lg font-bold"
                style={{ color: '#0b1f92' }}
              >
                Usuario
              </label>

              <div className="relative w-3/4">
                <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-2">
                  <EmailIcon className="w-6 h-6" style={{ color: '#00c8bb' }} />
                </div>

                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="example@example.com"
                  className="text-gray-900 text-sm rounded-sm outline-none block w-full ps-9 p-2.5 bg-white font-noto"
                  style={{ border: '1px solid #0b1f92' }}
                  autoComplete={false}
                />
              </div>
            </div>

            <div
              className={`flex items-center w-full ${touched.email && errors.email ? 'visible' : 'invisible'}`}
            >
              <div className="w-1/4 mr-4" />

              <div className="flex w-3/4">
                <p className="mt-0.5 text-sm text-red-600 leading-none font-noto">
                  {errors.email || '\u00A0'}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start justify-center">
            <div className="flex items-center w-full">
              <label
                htmlFor="password"
                className="block w-1/4 mr-4 text-lg font-bold"
                style={{ color: '#0b1f92' }}
              >
                Password
              </label>

              <div className="relative w-3/4">
                <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-2">
                  <LockIcon className="w-6 h-6" style={{ color: '#00c8bb' }} />
                </div>

                <Field
                  type="password"
                  id="password"
                  name="password"
                  className="text-gray-900 text-sm rounded-sm outline-none block w-full ps-9 p-2.5 bg-white font-noto"
                  style={{ border: '1px solid #0b1f92' }}
                  autoComplete={false}
                />
              </div>
            </div>

            <div
              className={`flex items-center w-full ${touched.password && errors.password ? 'visible' : 'invisible'}`}
            >
              <div className="w-1/4 mr-4" />

              <div className="flex w-3/4">
                <p className="mt-0.5 text-sm text-red-600 leading-none font-noto">
                  {errors.password || '\u00A0'}
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end space-x-6">
            <button
              type="submit"
              className="h-10 px-4 text-lg font-notoExtraCondensedBlack text-white hover:text-[#0b1f92] transition-colors duration-150 outline-none bg-[#0b1f92] hover:bg-[#00c8bb]"
              style={{
                border: '3px solid #00c8bb',
                borderRadius: '15px'
              }}
              disabled={loading && 'disabled'}
            >
              Acceder
            </button>

            <div className="text-center">
              <a
                href="#"
                className="h-10 font-notoBold text-[#0b1f92] hover:text-[#00c8bb] transition-colors duration-150 outline-none underline decoration-2 decoration-[#00c8bb] hover:decoration-[#0b1f92]"
              >
                Recuperar contraseña
              </a>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
}
