// PropTypes & HOOKS
import PropTypes from 'prop-types'

// COMPONENTS
import Navbar from './Navbar/Navbar'
import Title from './Title/Title'
import Sidebar from './Sidebar/Sidebar'
import Footer from './Footer/Footer'

export default function Layout({ children }) {
  return (
    <>
      <Navbar />

      <Sidebar />

      <div className="relative flex flex-col sm:ml-60">
        <div className="fixed w-full mt-24">
          <Title />
        </div>

        <div className="p-4 mt-36">{children}</div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
