import { useContext, useEffect } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import { TitleContext } from 'contexts/TitleContext'

// COMPONENTS
import DashboardClientes from 'components/Dashboard/DashboardClientes'
import DashboardFactures from 'components/Dashboard/DashboardFacturas'

// ASSETS
import loadingIco from 'components/ui/Logo/loading_azul.gif'

export default function DashboardPage() {
  // Contextos:
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGetClientes, setApiCallGetClientes] = useFetch()
  const [getApiCallGetFacturas, setApiCallGetFacturas] = useFetch()

  // ############################################################

  useEffect(() => {
    const dashboardTitle = { name: 'Panel - Resumen', buttons: [] }
    setTitle(dashboardTitle)

    const getApiCallClientes = {
      url: 'db/dash/entities/total',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de parella per Gènere.'
    }
    setApiCallGetClientes(getApiCallClientes)

    const getApiCallFacturas = {
      url: 'db/dash/facturas/total',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de parella per Gènere.'
    }
    setApiCallGetFacturas(getApiCallFacturas)
  }, [])

  // ############################################################

  return getApiCallGetClientes.data && getApiCallGetFacturas.data ? (
    <div>
      <section className="bg-graisy grid gap-y-2 grid-cols-1 sm:grid-cols-1 p-2 w-full mr-4 h-5/6">
        <article className="flex flex-wrap">
          <DashboardClientes fieldsDashClientes={getApiCallGetClientes.data} />
          <DashboardFactures fieldsDashFactures={getApiCallGetFacturas.data} />
        </article>
        <p className="text-white">.</p>
        <br />
      </section>
    </div>
  ) : (
    <div>
      <div className="h-full w-full flex justify-center items-center content-center">
        <p
          className="h-full text-customtechnoformline font-extrabold flex justify-center flex-col-reverse items-center"
          title="Cargando..."
        >
          ¡Cargando!
          <div className="mt-16 mb-8" style={{ width: '75px', height: '75px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
              <style>
                {`
                  @keyframes zoomInOut {
                    0%, 100%, 5%, 95% {
                      transform: translate(50px, 50px) scale(0);
                    }
                    20%, 80% {
                      transform: translate(10px, 10px) scale(.8);
                    }
                  }
                  
                  @keyframes spin {
                    from {
                      transform: rotate(0);
                    }
                    to {
                      transform: rotate(360deg);
                    }
                  }
                `}
              </style>

              <defs>
                <mask id="Mask">
                  <circle cx="50" cy="50" r="50" fill="#fff"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    style={{
                      animationName: 'zoomInOut',
                      animationDuration: '5.2s',
                      animationIterationCount: 'infinite',
                      animationTimingFunction: 'linear'
                    }}
                  ></circle>
                </mask>
              </defs>

              <g
                style={{
                  animationName: 'spin',
                  animationDuration: '1.3s',
                  transformOrigin: 'center',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear'
                }}
                fill="#100C97"
                mask="url(#Mask)"
              >
                <path d="M0 0h50v50H0zM50 50h50v50H50z"></path>
              </g>
            </svg>
          </div>
        </p>
      </div>
    </div>
  )
}
