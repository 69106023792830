import ColumnFilter from 'components/ui/Table/ColumnFilter'
import OrdersTable from 'components/ui/Table/OrdersTable'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

export default function OrdersCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  // Columnas:
  const columns = [
    {
      Header: 'Nº Pedido Cliente',
      accessor: 'Numero_pedido_Cliente',
      Filter: ColumnFilter,
      Type: 'Numero_pedido_Cliente',
      TitleDescription: 'Nº Pedido Cliente'
    },
    {
      Header: 'Nº Pedido Techno',
      accessor: 'Numero_pedido_Technoform',
      Filter: ColumnFilter,
      Type: 'Numero_pedido_Technoform',
      TitleDescription: 'Nº Pedido Technoform'
    },
    {
      Header: 'Pos.',
      accessor: 'Posicion',
      Filter: ColumnFilter,
      Type: 'Posicion',
      TitleDescription: 'Posición'
    },
    {
      Header: 'Referencia Cliente',
      accessor: 'Referencia_Cliente',
      Filter: ColumnFilter,
      Type: 'Referencia_Cliente',
      TitleDescription: 'Referencia Cliente'
    },
    {
      Header: 'Referencia Technoform',
      accessor: 'Referencia_Technoform',
      Filter: ColumnFilter,
      Type: 'Referencia_Technoform',
      TitleDescription: 'Referencia Technoform'
    },
    {
      Header: 'Conf.',
      accessor: 'Confeccion',
      Filter: ColumnFilter,
      Type: 'Confeccion',
      TitleDescription: 'Confección'
    },
    {
      Header: 'Material',
      accessor: 'Material',
      Filter: ColumnFilter,
      Type: 'Material',
      TitleDescription: 'Material'
    },
    {
      Header: 'Metros',
      accessor: 'Metros',
      Filter: ColumnFilter,
      Type: 'Metros',
      TitleDescription: 'Metros'
    },
    {
      Header: 'Long.',
      accessor: 'Longitud',
      Filter: ColumnFilter,
      Type: 'Longitud',
      TitleDescription: 'Longitud'
    },
    {
      Header: 'Varillas Paquete',
      accessor: 'Varillas_Paquete',
      Filter: ColumnFilter,
      Type: 'Varillas_Paquete',
      TitleDescription: 'Varillas Paquete'
    },
    {
      Header: 'Fecha Pedido',
      accessor: 'Fecha_Pedido',
      Filter: ColumnFilter,
      Type: 'Fecha_Pedido',
      TitleDescription: 'Fecha Pedido'
    },
    {
      Header: 'Fecha Entrega',
      accessor: 'Fecha_Entrega',
      Filter: ColumnFilter,
      Type: 'Fecha_Entrega',
      TitleDescription: 'Fecha Entrega'
    },
    {
      Header: 'Pallets Est.',
      accessor: 'Cantidad_Estimada',
      Filter: ColumnFilter,
      Type: 'Cantidad_Estimada',
      TitleDescription: 'Cantidad Estimada de Pallets'
    },
    {
      Header: 'P. Ext.',
      accessor: 'Produccion_Extranjera',
      Filter: ColumnFilter,
      Type: 'Produccion_Extranjera',
      TitleDescription: 'Producción Extranjera'
    }
  ]

  // Adaptar datos para la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Numero_pedido_Cliente: item.Numero_pedido_Cliente,
          Numero_pedido_Technoform: item.lineapedido_numeropedido,
          Posicion: item.Posicion,
          Referencia_Cliente: item.Referencia_Cliente,
          Referencia_Technoform: item.Referencia_Technoform,
          Confeccion: item.Confeccion,
          Material: item.Material,
          Metros: item.Metros,
          Longitud: item.Longitud,
          Varillas_Paquete: item.Varillas_Paquete,
          Fecha_Pedido: new Date(item.Fecha_Pedido).toLocaleDateString(),
          Fecha_Entrega: new Date(item.Fecha_Entrega).toLocaleDateString(),
          Cantidad_Estimada: item.Cantidad_Estimada,
          Produccion_Extranjera: item.Produccion_Extranjera
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full align-middle">
        <div className="overflow-hidden">
          {getTableData.length > 0 && (
            <OrdersTable
              data={getTableData}
              columns={columns}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}

OrdersCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
