import PropTypes from 'prop-types'
import { useState } from 'react'
import { Link } from 'wouter'
import SubMenuItems from './SubMenuItems'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

export default function MenuItems({ item, location }) {
  // Creamos una variable que decidirá cuando abrir y cerrar un menú deplegable, cada elemento
  // del menú tendrà su propia variable para decirid cuándo se desplegará y cuando se cerrará:
  const [dropdown, setDropdown] = useState(false)

  // Variable para desplegar un menú y así mostrar la página en la que nos encontramos:
  const [firstTimeAutoOpen, setFirstTimeAutoOpen] = useState(true)

  return (
    <>
      {/* Si el elemento del menú debe ser desplegable porque tiene elementos de submenú entramos aquí: */}
      {item.subMenuItems ? (
        <>
          <li key={item.title}>
            <Link
              className="main-menu-item"
              id={item.title}
              href={item.slug}
              onClick={() => {
                setDropdown((state) => !state)
              }}
            >
              <div
                // className={`flex items-center justify-between p-2 text-white rounded-sm group ${
                //   item.subMenuItems.some((submenu) => submenu.slug === location)
                //     ? 'bg-[#00c8bb]'
                //     : item.active && 'hover:bg-[#00c8bb] hover:text-white'
                // } ${item.title === 'Administrar' && 'cursor-default'}`}

                // className={`flex items-center justify-between p-2 rounded-sm group border-s-2 text-sm ${
                //   item.subMenuItems.some((submenu) => submenu.slug === location)
                //     ? 'text-[#00c8bb] border-[#00c8bb]'
                //     : item.active &&
                //       'border-transparent text-white hover:border-[#00c8bb] hover:text-[#00c8bb] focus:outline-none focus:border-[#00c8bb] focus:text-[#00c8bb]'
                // }`}

                className={`flex items-center justify-between px-2 py-1 group border-s-2 ${
                  item.subMenuItems.some(
                    (submenu) => submenu.slug === location
                  ) || dropdown
                    ? 'text-[#00c8bb] border-[#00c8bb]'
                    : item.active &&
                      'border-transparent text-white border-white hover:border-[#00c8bb] hover:text-[#00c8bb] focus:outline-none focus:border-[#00c8bb] focus:text-[#00c8bb]'
                }`}
              >
                <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
                  {item.title}
                </span>

                {item.subMenuItems &&
                  (dropdown ? (
                    <AiFillCaretUp
                      className="menu-item-icon"
                      color={`${item.subMenuItems.some((submenu) => submenu.slug === location) || dropdown ? '#00c8bb' : item.active && 'ffffff'}`}
                    />
                  ) : (
                    <AiFillCaretDown
                      className="menu-item-icon"
                      color={`${item.subMenuItems.some((submenu) => submenu.slug === location) || dropdown ? '#00c8bb' : item.active && 'ffffff'}`}
                    />
                  ))}
              </div>
            </Link>
          </li>

          <ul
            className={`space-y-2 font-medium list-none ${!dropdown && 'hidden'}`}
          >
            {item.subMenuItems.map((submenu, index) => {
              // Si la URL coincide con un elemento que haya en el menú y este se encuentra oculto en un submenú, lo que haremos
              // será obligar a que se abra este desplegable solo 1 vez al recargar una página para que así el usuario sepa dónde se
              // ubica en la aplicación:
              if (location === submenu.slug && firstTimeAutoOpen) {
                setDropdown(true)
                setFirstTimeAutoOpen(false)
              }

              return (
                <SubMenuItems
                  submenu={submenu}
                  key={submenu.slug}
                  location={location}
                />
              )
            })}
          </ul>
        </>
      ) : (
        <li key={item.title}>
          <Link id={item.title} href={item.slug}>
            <div
              // className={`flex items-center justify-between p-2 text-white rounded-sm group ${
              //   item.slug === location
              //     ? 'bg-[#00c8bb]'
              //     : item.active && 'hover:bg-[#00c8bb] hover:text-white'
              // }`}
              className={`flex items-center justify-between px-2 py-1 group border-s-2 ${
                item.slug === location
                  ? 'text-[#00c8bb] border-[#00c8bb]'
                  : item.active &&
                    'border-white text-white hover:border-[#00c8bb] hover:text-[#00c8bb] focus:outline-none focus:border-[#00c8bb] focus:text-[#00c8bb]'
              }`}
            >
              <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
                {item.title}
              </span>

              {item.subMenuItems && (
                <AiFillCaretDown
                  className={`${
                    item.slug === location
                      ? 'text-white'
                      : item.active && 'text-[#00c8bb] hover:text-[#00c8bb]'
                  }`}
                  // color={`${item.slug === location ? '#00c8bb' : item.active && '#00c8bb'}`}
                  color={'#ffffff'}
                />
              )}
            </div>
          </Link>
        </li>
      )}
    </>
  )
}

MenuItems.propTypes = {
  item: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.string
}
