import PropTypes from 'prop-types'

// Images
import logoGestinet from 'assets/img/logo-gestinet.png'

export default function LogoGestinet({ parentClasses, imgClasses, link }) {
  return (
    <div className={parentClasses}>
      <a href={link} target="_BLANK" rel="noreferrer" className="print:hidden">
        <img className={imgClasses} src={logoGestinet} alt="Gestinet" />
      </a>
    </div>
  )
}

LogoGestinet.propTypes = {
  parentClasses: PropTypes.string.isRequired,
  imgClasses: PropTypes.string.isRequired,
  link: PropTypes.string
}
