import Menu from 'components/layout/Menu/Menu'
import LogoGestinet from 'components/ui/Logo/LogoGestinet'
import { Link } from 'wouter'

export default function Sidebar() {
  return (
    <>
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full print:hidden bg-customtechnoform w-60 pt-[104px] pb-24 sm:translate-x-0 pr-1"
        // className="fixed top-0 left-0 z-40 h-screen mt-2 transition-transform -translate-x-full pt-[134px] print:hidden bg-customtechnoform w-60 sm:translate-x-0"
        // className="fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full pt-28 print:hidden bg-customtechnoform w-60 sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div
          id="sidebar-menu"
          className="h-full px-3 pb-4 overflow-y-auto bg-customtechnoform"
        >
          <Menu device="desktop" />
        </div>

        <div className="fixed bottom-12 flex items-center justify-center w-full m-auto px-3 h-12 border-t-2 border-[#00c8bb] font-medium list-none">
          <Link className="w-full" href="/logout">
            <div className="flex items-center justify-between px-2 py-1 group border-s-2 text-white border-white hover:border-[#00c8bb] hover:text-[#00c8bb] focus:outline-none focus:border-[#00c8bb] focus:text-[#00c8bb]">
              <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
                Cerrar Sesión
              </span>
            </div>
          </Link>
        </div>

        <LogoGestinet
          parentClasses={
            'fixed bottom-0 flex items-center justify-center w-full m-auto h-12 border-t-2 border-[#00c8bb]'
          }
          imgClasses={'w-20'}
          link={'https://www.gestinet.com'}
        />
      </aside>
    </>
  )
}
