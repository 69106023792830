import FilterDown from 'components/icons/FilterDown'
import FilterUp from 'components/icons/FilterUp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import OrdersTableBar from './OrdersTableBar'

export default function OrdersTable({ data, columns, handleClick }) {
  // Estado para guardar los datos filtrados
  const [filteredData, setFilteredData] = useState(data)

  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow,
    rows,
    setAllFilters
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize } = state

  // Actualizar el estado filteredData con las filas filtradas
  useEffect(() => {
    setFilteredData(rows.map((row) => row.original)) // Guardar solo los datos filtrados
  }, [rows])

  return (
    <div className="flex flex-col">
      <div className="inline-block min-w-full align-middle">
        <div className="flex items-center justify-end w-full px-2 pb-2 space-x-2 font-bold text-left">
          <button
            type="button"
            className="h-10 px-4 font-notoBold text-sm text-[#0b1f92] hover:text-[#00c8bb] transition-colors duration-150 outline-none underline decoration-2 decoration-[#00c8bb] hover:decoration-[#0b1f92]"
            onClick={() => {
              setAllFilters([])

              columns.forEach((column) => {
                localStorage.setItem(column.accessor, undefined)
              })
            }}
          >
            Borrar
          </button>
        </div>

        {/* Tabla: */}
        <table className="min-w-full table-fixed" {...getTableProps()}>
          {/* Cabecera */}
          <thead className="bg-[#00c8bb] border-2 border-[#00c8bb]">
            {headerGroups.map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="divide-x divide-dashed"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="text-white hover:bg-[#009d96] font-notoBlack h-20 min-h-20 p-1"
                    {...header.getHeaderProps(
                      header.getSortByToggleProps({
                        title: header.TitleDescription
                      })
                    )}
                  >
                    <div className="flex flex-col justify-between h-full space-y-2">
                      <div className="text-sm text-left">
                        {header.render('Header')}

                        {header.isSorted ? (
                          header.isSortedDesc ? (
                            <FilterDown
                              width={10}
                              height={10}
                              className="inline-block"
                              fill="black"
                            />
                          ) : (
                            <FilterUp
                              width={10}
                              height={10}
                              className="inline-block"
                              fill="black"
                            />
                          )
                        ) : (
                          ''
                        )}
                      </div>

                      <div>{header.canFilter && header.render('Filter')}</div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Cuerpo */}
          <tbody
            className="bg-white divide-y divide-gray-200 border-2 border-[#00c8bb]"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  // onClick={(e) => handleClick && handleClick(e, row.original)}
                  // className={`hover:bg-[#c6f8f6] hover:font-bold cursor-pointer divide-x divide-dashed divide-[#00c8bb]
                  //   ${index % 2 !== 0 && 'bg-[#dddddd]'}`}
                  className={`divide-x divide-dashed divide-[#00c8bb]
                    ${index % 2 !== 0 && 'bg-[#dddddd]'}`}
                >
                  {/* Renderiza las celdas de la fila */}
                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Numero_pedido_Cliente}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Numero_pedido_Technoform}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Posicion}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Referencia_Cliente}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Referencia_Technoform}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Confeccion}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Material}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Metros}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Longitud}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Varillas_Paquete}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {new Date(
                      page[index].original.Fecha_Pedido
                    ).toLocaleDateString()}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Fecha_Entrega
                      ? new Date(
                          page[index].original.Fecha_Entrega
                        ).toLocaleDateString()
                      : 'No asignada'}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Cantidad_Estimada}
                  </td>

                  <td className="py-2 text-sm font-noto">
                    {page[index].original.Produccion_Extranjera === 'X'
                      ? 'Si'
                      : 'No'}
                  </td>
                </tr>
              )
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <OrdersTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={filteredData}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

OrdersTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func
}
