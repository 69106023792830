import flecha from 'assets/img/imageInvert.png'
import { saveAs } from 'file-saver'
import JsPDF, { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'

export default function OrdersTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  datos
}) {
  const rowsEmpty = datos.length === 0
  const exportToExcel = () => {
    // Mapeo de títulos originales a nuevos títulos sin guiones bajos
    const titlesMapping = {
      Numero_pedido_Cliente: 'Nº Pedido Cliente',
      Numero_pedido_Technoform: 'Nº Pedido Techno',
      Posicion: 'Pos.',
      Referencia_Cliente: 'Referencia Cliente',
      Referencia_Technoform: 'Referencia Technoform',
      Confeccion: 'Conf.',
      Material: 'Material',
      Metros: 'Metros',
      Longitud: 'Long.',
      Varillas_Paquete: 'Varillas Paquete',
      Fecha_Pedido: 'Fecha Pedido',
      Fecha_Entrega: 'Fecha Entrega',
      Cantidad_Estimada: 'Pallets Est.',
      Produccion_Extranjera: 'P. Ext.'
    }

    // Formatear las fechas en formato español (DD/MM/YYYY)
    const datosFormateados = datos.map((item) => ({
      ...item,
      Fecha_Pedido: item.Fecha_Pedido
        ? formatDateToSpanish(item.Fecha_Pedido)
        : '',
      Fecha_Entrega: item.Fecha_Entrega
        ? formatDateToSpanish(item.Fecha_Entrega)
        : ''
    }))

    // Crear la hoja de trabajo con los nuevos títulos
    const worksheet = XLSX.utils.json_to_sheet(datosFormateados)

    // Reemplazar los títulos en la hoja de trabajo
    const newHeaders = Object.keys(titlesMapping).map(
      (key) => titlesMapping[key]
    )
    worksheet['!cols'] = newHeaders.map(() => ({ wch: 20 })) // Ajustar el ancho de las columnas
    newHeaders.forEach((header, index) => {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: index })].v = header // Asignar nuevos títulos
    })

    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(data, 'pedidos_clientes.xlsx')
  }

  // Función para formatear la fecha en DD/MM/YYYY
  const formatDateToSpanish = (dateString) => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0') // Obtener el día con dos dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0') // Obtener el mes con dos dígitos (0-11)
    const year = date.getFullYear() // Obtener el año
    return `${day}/${month}/${year}` // Retornar en formato DD/MM/YYYY
  }

  // Función para exportar todos los usuarios Individuales en un PDF:
  function generateSinglesPDF() {
    const doc = new JsPDF()

    doc.autoTable({
      head: [
        [
          'Nº Pedido Cliente',
          'Nº Pedido Techno',
          'Pos.',
          'Referencia Cliente',
          'Referencia Technoform',
          'Conf.',
          'Material',
          'Metros',
          'Long.',
          'Varillas Paquete',
          'Fecha Pedido',
          'Fecha Entrega',
          'Pallets Est.',
          'P. Ext.'
        ]
      ],
      body: datos.map((dato, i) => [
        dato.Numero_pedido_Cliente,
        dato.lineapedido_numeropedido,
        dato.Posicion,
        dato.Referencia_Cliente,
        dato.Referencia_Technoform,
        dato.Confeccion,
        dato.Material,
        dato.Metros,
        dato.Longitud,
        dato.Varillas_Paquete,
        dato.Fecha_Pedido,
        dato.Fecha_Entrega,
        dato.Cantidad_Estimada,
        dato.Produccion_Extranjera
      ]),
      styles: {
        fontSize: 8,
        cellPadding: 1,
        lineWidth: 0.1,
        lineColor: [0, 157, 150]
      },
      columnStyles: {
        0: { cellWidth: 12 }, // 'Nº Pedido Cliente'
        1: { cellWidth: 12 }, // 'Nº Pedido Techno'
        2: { cellWidth: 8 }, // 'Posición'
        3: { cellWidth: 16 }, // 'Referencia Cliente'
        4: { cellWidth: 18 }, // 'Referencia Technoform'
        5: { cellWidth: 10 }, // 'Confección'
        6: { cellWidth: 25 }, // 'Material'
        7: { cellWidth: 12 }, // 'Metros'
        8: { cellWidth: 10 }, // 'Longitud'
        9: { cellWidth: 15 }, // 'Varillas Paquete'
        10: { cellWidth: 16 }, // 'Fecha Pedido'
        11: { cellWidth: 16 }, // 'Fecha Entrega'
        12: { cellWidth: 13 }, // 'Cantidad Estimada'
        13: { cellWidth: 8 } // 'Producción Extranjera'
      },
      margin: { top: 20, right: 10, bottom: 20, left: 10 },
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      headStyles: {
        fillColor: [0, 200, 187],
        lineWidth: 0.1,
        lineColor: [0, 157, 150]
      },
      alternateRowStyles: { fillColor: [221, 221, 221] },
      showHead: 'everyPage',
      showFoot: 'everyPage',
      didDrawPage: (data) => {
        const pageWidth = doc.internal.pageSize.width
        const pageHeight = doc.internal.pageSize.height

        // Footer content (page number)
        doc.setFontSize(10)
        doc.setFont('helvetica', 'bold')
        doc.text(
          `${data.pageNumber}`,
          pageWidth / 2,
          pageHeight - 10,
          { align: 'center' }
        )

        // Header content - replicating the logo design
        const headerText1 = 'Mi' // First part "Mi"
        const headerText2 = 'Technoform' // Second part "Technoform"

        const space = 18

        // Set font size and family for the header text
        doc.setFontSize(24)

        // First part of the text ("Mi") with color #0b1f92
        doc.setTextColor(11, 31, 146) // RGB for #0b1f92
        const textWidth1 = doc.getTextWidth(headerText1)
        doc.text(
          headerText1,
          (pageWidth - textWidth1 - doc.getTextWidth(headerText2)) / 2,
          space
        )

        // Second part of the text ("Technoform") with color #00c8bb
        doc.setTextColor(0, 200, 187) // RGB for #00c8bb
        const textWidth2 = doc.getTextWidth(headerText2)
        doc.text(
          headerText2,
          (pageWidth - textWidth2 - doc.getTextWidth(headerText1)) / 2 +
            textWidth1,
          space
        )
      }
    })

    const fechaPDF = new Date()

    doc.save(
      'Pedidos_' +
        fechaPDF.getDate() +
        '_' +
        (fechaPDF.getMonth() + 1) +
        '_' +
        fechaPDF.getFullYear() +
        '.pdf'
    )
  }

  return (
    <div className="flex items-start justify-between px-2 pt-2 text-center bg-white print:hidden">
      <div
        className="flex items-center justify-center float-left space-x-2 text-left cursor-pointer font-notoBold"
        title={
          'En esta página se muestran las líneas de peidos realizadas en los últimos 24 meses. \n\n' +
          "En el campo 'Conf.'(Confección) las abreviaturas mostradas significan lo siguiente:" +
          '\n - N: sin cordón termofusible ' +
          '\n - G: con cordón termofusible ' +
          '\n - UB: sin empaquetar ' +
          '\n - L: marcado láser ' +
          '\n - WD: Draining ' +
          '\n - AF: Adhesive Film ' +
          '\n\n. . .'
        }
      >
        <div
          className="text-[#0b1f92] rounded-full w-8 h-8 text-2xl flex justify-center items-center"
          style={{
            border: '3px solid #00c8bb'
          }}
        >
          <span>i</span>
        </div>

        <div className="relative inline-block tooltip text-[#0b1f92] text-sm">
          Sobre esta página
        </div>
      </div>

      <div className="flex flex-col items-center justify-center space-y-2 text-sm font-noto">
        <div className="flex flex-row items-center justify-center">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="font-bold text-white rounded-full bg-[#00c8bb] disabled:opacity-0"
            style={{ width: '24px', marginRight: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ transform: 'rotate(180deg)', margin: 'auto' }}
            />
          </button>

          <span>
            Pàgina <strong>{pageIndex + 1}</strong> de{' '}
            <strong>
              {pageOptions.length} {' ('}
              {datos.length} registres{')'}
            </strong>
          </span>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="font-bold text-white rounded-full bg-[#00c8bb] disabled:opacity-0"
            style={{ width: '24px', marginLeft: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ margin: 'auto' }}
            />
          </button>
        </div>

        <select
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
          className="float-right h-10 px-3 outline-none cursor-pointer"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[25, 75, 150, 250, 500].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} registres
            </option>
          ))}
        </select>
      </div>

      <div className="flex items-center justify-center float-left space-x-2 font-bold text-left">
        <button
          type="button"
          className="h-10 px-4 font-notoExtraCondensedBlack text-[#0b1f92] transition-colors duration-150 outline-none bg-white hover:bg-[#00c8bb]"
          onClick={exportToExcel}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
        >
          Exportar Excel
        </button>

        <button
          type="button"
          className="h-10 px-4 font-notoExtraCondensedBlack text-[#0b1f92] transition-colors duration-150 outline-none bg-white hover:bg-[#00c8bb]"
          onClick={generateSinglesPDF}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
        >
          Exportar PDF
        </button>
      </div>
    </div>
  )
}

OrdersTableBar.propTypes = {
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  datos: PropTypes.array
}
